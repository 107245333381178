import { Helmet } from 'react-helmet';
import { Contact } from "../Components/Contact";
import { Cta } from "../Components/Cta";
import { NavBar } from "../Components/NavBar";
import { SideBar } from "../Components/SideBar";

import data from "../data.json";

export function ContactPage() {
    const navData = data.NavBar;
    const sideData = data.SideBar;
    const ctaData = data.ContactPage.cta;
    const contactData = data.ContactPage.contact;

    return (
        <div className="contactPage" style={{ backgroundColor: data.ContactPage.backgroundColor }}>
            <Helmet>
                <title>Entre em Contato | Delleprane Studio</title>
                <meta name="description" content="Fale com o Delleprane Studio para desenvolver projetos incríveis. Entre em contato e descubra como podemos ajudar a transformar suas ideias em realidade." />
                <meta name="keywords" content="contato, Delleprane Studio, desenvolvimento de sites, design criativo, soluções digitais, suporte técnico, orçamento personalizado, projetos web, design de sites, consultoria digital" />

                {/* Open Graph - SEO para redes sociais */}
                <meta property="og:title" content="Entre em Contato | Delleprane Studio" />
                <meta property="og:description" content="Conecte-se com o Delleprane Studio para discutir seus projetos e encontrar soluções criativas para o seu negócio. Estamos prontos para ajudar!" />
                <meta property="og:image" content="URL_DA_IMAGEM_DE_CONTATO" />
                <meta property="og:url" content="https://www.delleprane.com.br/contact" />
            </Helmet>


            <div className="contactPage-container">
                <NavBar navData={navData} />
                <Contact data={contactData} />
                <Cta data={ctaData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    );
}
