import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';

import "./WorkFlow.css";

interface ContentData {
    text: string;
}

interface CardsData {
    title: string;
    backgroundColor: string;
    textColor: string;
    content?: ContentData[];
}

interface WorkData {
    title: string;
    textColor: string;
    type: string;
    backgroundColor: string;
    borderColor: string;
    image: string;
    cards: CardsData[];
}

interface DataProps {
    data: WorkData;
}

export function WorkFlow({ data }: DataProps) {
    return (
        <section className="work-flow">
            <header className={`work-flow-container ${data.type === 'work' ? 'work-title' : 'title'}`} style={{ backgroundImage: `url(${data.image})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                <div className="padding" >
                    <h2 style={{ color: data.textColor,    WebkitTextStroke: "1px #000",}}>{data.title}</h2>
                    <div className="cards">
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            loop={true}
                            navigation={true}
                            modules={[ Navigation]}
                            className="mySwiper"
                        >
                            {data.cards.map((card, index) => (
                                <SwiperSlide key={index}>
                                    <article
                                        className="card"
                                        style={{ backgroundColor: data.backgroundColor}}
                                        key={index}
                                    >
                                        <header className="content">
                                            <h3 style={{ color: card.textColor }}>{card.title}</h3>
                                            <section>
                                                {card.content?.map((content, contentIndex) => (
                                                    <p key={contentIndex} style={{ color: card.textColor }}>
                                                        {content.text}
                                                    </p>
                                                ))}
                                            </section>
                                        </header>
                                    </article>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </header>
        </section>
    );
}
