import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';

import './Portfolio.css';
import { Button } from "../Button";

import { motion } from "framer-motion";

interface Button {
    title: string;
    borderColor: string;
    link: string;
    textColor: string;
}

interface PortfolioContent {
    text: string
}

interface PortfolioItem {
    image?: string;
    content?: PortfolioContent[];
    link?: string;
    align?: string;
}

interface PortfolioCategory {
    items?: PortfolioItem[];
}

interface PortfolioData {
    title: string;
    textColor: string;
    type: string;
    highlightColor: string;
    backgroundColorSecundary: string;
    categories: PortfolioCategory;
    buttons: Button[];
}

interface PortfolioProps {
    data: PortfolioData;
}

export function Portfolio({ data }: PortfolioProps) {

    return (
        <section className="portfolio">
            <div className="portfolio-container">
                <h2 style={{ color: data.textColor, WebkitTextStroke: "1px #000" }}>{data.title}</h2>
                <div className="projects">
                    {data.categories.items?.map((item) => (
                        
                        <div className="content-card">
                            <div className="content">
                            <motion.div
                            className='motion'
                            initial={{ opacity: 0 }}
                            whileInView={{opacity: 1}}
                            whileHover={{ scale: 1.1 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                        > 
                                <a href={item.link} target='_blank'>
                                    <img src={item.image} alt="imagem de cases produzidos por delleprane studio" />
                                </a>
                                </motion.div>
                                <a href={item.link} style={{ color: data.highlightColor }} target='_blank'>Navegar pelo Projeto</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
