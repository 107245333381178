import { Helmet } from 'react-helmet';
import { Cta } from "../Components/Cta";
import { Feature } from "../Components/Feature";
import { Hero } from "../Components/Hero";
import { NavBar } from "../Components/NavBar";
import { Portfolio } from "../Components/Portifolio";
import { SideBar } from "../Components/SideBar";
import { WorkFlow } from "../Components/WorkFlow";

import data from "../data.json";
import { AligneIcons } from '../Components/AligneIcons';
import { AligneText } from '../Components/AligneText';

export function LandingPages() {
    const navData = data.NavBar;
    const sideData = data.SideBar;
    const heroData = data.Landings.hero;
    const aligneData = data.Landings.about;
    const featureData = data.Landings.feature;
    const portfolioData = data.Landings.portfolio;
    const aligneIconsData = data.Landings.aligneIcons;
    const workFlowData = data.Landings.workFlow;
    const ctaData = data.Landings.cta;

    return (
        <div className="landingPages" style={{ backgroundColor: data.Restyling.backgroundColor }}>
            <Helmet>
                <title>{heroData.title} | Criação de Landing Pages Eficientes | Delleprane Studio</title>
                <meta name="description" content="Impulsione seus resultados com landing pages estratégicas e visualmente impactantes criadas pelo Delleprane Studio. Alavanque conversões e destaque sua marca." />
                <meta name="keywords" content="criação de landing pages, landing pages profissionais, design de landing pages, páginas de conversão, otimização de landing pages, desenvolvimento de landing pages, design responsivo, Delleprane Studio, soluções digitais, aumento de conversão, páginas otimizadas, experiência do usuário" />

                {/* Open Graph - SEO para redes sociais */}
                <meta property="og:title" content={`${heroData.title} | Criação de Landing Pages Eficientes | Delleprane Studio`} />
                <meta property="og:description" content="Aumente suas conversões com landing pages otimizadas e criativas desenvolvidas pelo Delleprane Studio. Descubra como podemos ajudar seu negócio a crescer." />
                <meta property="og:image" content={heroData.image} />
                <meta property="og:url" content="https://www.delleprane.com.br/landing-pages" />
            </Helmet>

            <div className="landing-container">
                <NavBar navData={navData} />
                <Hero hero={heroData} />
                <AligneText data={aligneData} />
                <Feature data={featureData} />
                <Portfolio data={portfolioData} />
                <AligneIcons data={aligneIconsData} />
                <WorkFlow data={workFlowData} />
                <Cta data={ctaData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    );
}
