import { motion } from "framer-motion";

import "./Button.css"

interface Button {
    title: string;
    borderColor: string;
    link: string;
    textColor: string;
    download?: boolean
    type:string;
}

interface ButtonData {
    buttons: Button[];
}

interface ButtonProps {
    data: ButtonData;
}

export function Button({ data }: ButtonProps) {
    return (
        <div className="buttons">
            {
                data.buttons.map((button,index) => (
                    <motion.div
                        className='button-animation'
                        whileHover={{ scale: 1.1 }}
                        key={index}
                    >
                        <a href={button.link}
                            download={button.download ? true : undefined}
                            key={index}
                            target={button.type === "out" ? "_blank" : "_self"}
                            style={{
                                color: button.textColor,
                                border: '2px solid white', boxShadow: `0px 0px 9px 2px ${button.borderColor}`
                            }}
                        >{button.title}
                        </a>


                    </motion.div>
                ))
            }
        </div>
    )
}