import { Button } from "../Button";
import "./AligneText.css"
import { motion } from "framer-motion";

interface AligneData {
    textColor: string;
    image: string;
    title: string;
    texts: { aligne: string; content: string }[];
    buttons: { textColor: string; title: string; borderColor: string; link: string, type: string }[];
    button?: { textColor: string; title: string; borderColor: string; link: string, type: string };
}


interface DataProps {
    data: AligneData;
}

export function AligneText({ data }: DataProps) {
    return (
        <div className="aligne-text">
            <div className="aligne-text-container">
                <div className="content" style={{ color: data.textColor }}>
                    <div className="title">
                        <h3>{data.title}</h3>
                        <motion.div
                             animate={{
                                opacity: [1, 0.8, 0, 0.8, 1], // Controle de opacidade para simular desaparecimentos
                            }}
                            transition={{
                                duration: 1, // Duração rápida do glitch
                                repeat: Infinity, // Repete infinitamente
                                repeatType: "loop",
                                ease: "easeInOut", // Suavidade nas transições
                            }}
                        >
                            <img src={data.image} alt={`imagem referente a ${data.title}`} aria-label={`imagem referente a ${data.title}`} />
                        </motion.div>
                    </div>
                    <div className="info">
                        {data.texts.map((text, index) => (
                            <p key={index}>{text.content}</p>
                        ))}
                    </div>
                </div>

                <Button data={data} />
            </div>
        </div >
    );
}

