import { Helmet } from 'react-helmet';
import { About } from "../Components/About";
import { Contact } from "../Components/Contact";
import { Hero } from "../Components/Hero";
import { NavBar } from "../Components/NavBar";
import { Price } from "../Components/Price";
import { SideBar } from "../Components/SideBar";
import { WorkFlow } from "../Components/WorkFlow";

import data from "../data.json";
import { Cta } from '../Components/Cta';

export function AboutPage() {
    const navData = data.NavBar;
    const heroData = data.AboutPage.hero;
    const sideData = data.SideBar;
    const workFlowData = data.AboutPage.workFlow;
    const priceData = data.AboutPage.price;
    const aboutData = data.AboutPage.about;
    const ctaData = data.AboutPage.cta;
    const contactData = data.AboutPage.contact;

    return (
        <div className="about" style={{ backgroundColor: data.AboutPage.backgroundColor }}>
            <Helmet>
                <title>Sobre Nós | Delleprane Studio</title>
                <meta name="description" content="Conheça o Delleprane Studio. Descubra nossa história, missão, visão e como nossas soluções criativas e inovadoras podem transformar seu negócio e impulsionar seu sucesso online." />
                <meta name="keywords" content="sobre, Delleprane Studio, design criativo, desenvolvimento web, soluções personalizadas, design de sites, inovação, missão e visão, criatividade, transformação digital" />

                {/* Open Graph - SEO para redes sociais */}
                <meta property="og:title" content="Sobre Nós | Delleprane Studio" />
                <meta property="og:description" content="Explore o Delleprane Studio, conheça nossa jornada, valores e como podemos ajudar sua empresa com soluções digitais inovadoras e personalizadas." />
                <meta property="og:image" content={heroData.image} />
                <meta property="og:url" content="https://www.delleprane.com.br/about" />
            </Helmet>


            <div className="about-container">
                <NavBar navData={navData} />
                <Hero hero={heroData} />
                <About data={aboutData} />
                <Price data={priceData} />
                <WorkFlow data={workFlowData} />
                <Cta data={ctaData} />
                <Contact data={contactData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    );
}
