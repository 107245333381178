import { Helmet } from 'react-helmet';
import { Contact } from "../Components/Contact";
import { Differential } from "../Components/Differential/Differential";
import { Hero } from "../Components/Hero";
import { NavBar } from "../Components/NavBar";
import { Price } from "../Components/Price";
import { Resume } from "../Components/Resume/Resume";
import { SideBar } from "../Components/SideBar";
import { WorkFlow } from "../Components/WorkFlow";

import data from "../data.json";
import { Cta } from '../Components/Cta';

export function Freelance() {
    const navData = data.NavBar;
    const sideData = data.SideBar;
    const heroData = data.Freelance.hero;
    const resumeData = data.Freelance.resume;
    const differentialData = data.Freelance.differential;
    const ctaData = data.Freelance.cta;
    const workFlowData = data.Freelance.workFlow;
    const contactData = data.Freelance.contact;
    const priceData = data.Freelance.price;

    return (
        <div className="freelance" style={{ backgroundColor: data.Freelance.backgroundColor }}>
            <Helmet>
                <title>{heroData.title} | Freelance Front-End Developer | Delleprane Studio</title>
                <meta name="description" content="Contrate serviços de freelance front-end com o Delleprane Studio. Desenvolvimento web responsivo, design moderno e soluções personalizadas para criar experiências digitais incríveis." />
                <meta name="keywords" content="freelance front-end, desenvolvedor freelance, serviços freelancer, desenvolvimento web, design de sites, web design, sites responsivos, soluções personalizadas, Delleprane Studio, design criativo, otimização de sites, landing pages, desenvolvimento front-end, HTML, CSS, JavaScript, React" />

                {/* Open Graph - SEO para redes sociais */}
                <meta property="og:title" content={`${heroData.title} | Freelance Front-End Developer | Delleprane Studio`} />
                <meta property="og:description" content="Especialista em desenvolvimento front-end. Criamos sites modernos, responsivos e personalizados para atender às necessidades do seu negócio. Confira os serviços de freelance disponíveis." />
                <meta property="og:image" content={heroData.image} />
                <meta property="og:url" content="https://www.delleprane.com.br/freelance" />
            </Helmet>


            <div className="freelance-container">
                <NavBar navData={navData} />
                <Hero hero={heroData} />
                <Resume data={resumeData} />
                <Differential data={differentialData} />
                <WorkFlow data={workFlowData} />
                <Cta data={ctaData} />
                <Contact data={contactData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    );
}
