import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { initializeAnalytics, trackPageView } from './analytics';
import { RoutesT } from './Routes';
import './App.css';

const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Rastreamento de troca de rota
    trackPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    // Inicializa o Google Analytics quando o app é carregado
    initializeAnalytics();
  }, []);

  return (
    <Router>
      {/* Componente que monitora as mudanças de rota */}
      <AnalyticsTracker />
      <div className="App">
        <div className="app-container">
          <RoutesT />
        </div>
      </div>
    </Router>
  );
}

export default App;
