import { motion } from "framer-motion";

import "./Carousel.css"

interface CarouselData {
    title: string;
    link: string;
    textColor: string;
    icon: string;
}

interface DataProps {
    data: CarouselData[];
}

export function Carousel({ data }: DataProps) {
    return (
        <div className="carousel">
            <div className="carousel-container">
                <div className="desktop">
                    {data.map((item, index) => (
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 300 }}
                            className="icon-container"
                            key={index}
                        >
                            <a href={item.link} style={{color:item.textColor}} key={index}>
                                <img loading="lazy" src={item.icon} alt={`icone de ${item.title}`} />
                                <p>{item.title}</p>
                            </a>

                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
}
