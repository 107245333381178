import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./NavBar.css";

import { HamburgerMenu } from "./MobileNavBar/HamburgerMenu";

interface ContentItem {
    name: string;
    icon: string;
    link: string;
}

interface NavItem {
    name: string;
    link: string;
    content?: ContentItem[];
}

interface NavBarData {
    textColor: string;
    backgroundColor: string;
    icon: string;
    itens: NavItem[];
}

interface NavProps {
    navData: NavBarData
}

export function NavBar({ navData }: NavProps) {
    const [modalContent, setModalContent] = useState<ContentItem[] | null>(null);

    const openModal = (content: ContentItem[]) => {
        setModalContent(content);
    };

    const closeModal = () => {
        setModalContent(null);
    };

    useEffect(() => {
        if (modalContent) {
            const handleScroll = () => {
                closeModal();
            };
            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, [modalContent]);

    return (
        <nav className="navbar" aria-label="Navegação principal">
            <div
                className="navbar-container"
                style={{
                    color: navData.textColor,
                    backgroundColor: navData.backgroundColor,
                }}
                onClick={closeModal}
            >
                <div className="menu-itens">
                    <a href="/" className="image" aria-label="Página inicial">
                        <img
                            loading="lazy"
                            src={navData.icon}
                            alt="Logo da Delleprane Studio, design criativo e desenvolvimento de sites"
                        />
                    </a>
                    <div className="navigation" onClick={(e) => e.stopPropagation()}>
                        <ul>
                            {navData.itens.map((iten, index) => (
                                <li key={index} style={{ listStyle: "none" }}>
                                    {iten.content ? (
                                        <a
                                            href="#!"
                                            aria-label={`Menu de ${iten.name}`}
                                            onClick={() => openModal(iten.content!)}
                                            style={{
                                                color: navData.textColor,
                                                textDecoration: "none",
                                            }}
                                        >
                                            {iten.name}
                                        </a>
                                    ) : (
                                        <a
                                            href={iten.link}
                                            style={{
                                                color: navData.textColor,
                                                textDecoration: "none",
                                            }}
                                            onClick={closeModal}
                                        >
                                            {iten.name}
                                        </a>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="menu-itens-mobile">
                    <a href="/" className="image" aria-label="Página inicial">
                        <img
                            loading="lazy"
                            src={navData.icon}
                            alt="Logo da Delleprane Studio"
                        />
                    </a>
                    <HamburgerMenu
                        items={navData.itens}
                        textColor={navData.textColor}
                        backgroundColor={navData.backgroundColor}
                    />
                </div>

                {/* Modal */}
                {modalContent && (
                    <motion.div
                        onClick={closeModal}
                    >
                        <motion.div
                            className="modal-content"
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                            style={{
                                backgroundColor: navData.backgroundColor,
                            }}
                        >
                            <ul>
                                {modalContent.map((contentItem, index) => (
                                    <li key={index}>
                                        <a
                                            href={contentItem.link}
                                            style={{
                                                color: navData.textColor,
                                            }}
                                        >
                                            <img
                                                src={contentItem.icon}
                                                alt={`Ícone de ${contentItem.name}`}
                                            />
                                            {contentItem.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </motion.div>
                    </motion.div>
                )}
            </div>
        </nav>
    );
}
