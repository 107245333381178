import { Button } from "../Button";
import "./Hero.css"

interface Button {
    title: string;
    borderColor: string;
    link: string;
    type: string;
    textColor: string;
}

interface HeroData {
    mime: string;
    image: string;
    title: string;
    subTtitle: string;
    overlayColor?: string;
    titleColor: string;
    subTtitleColor: string;
    highlightColor: string;
    highlightTitle: string[];
    highlightSubTitle: string;
    buttons: Button[]
}

interface HeroProps {
    hero: HeroData;
}


export function Hero({ hero }: HeroProps) {
    return (
        <div className="hero">
            <div className="hero-container">
                {hero.mime === "video" ? (
                    <video autoPlay loop muted className="video-background">
                        <source src={hero.image} type="video/mp4" />
                        Seu navegador não suporta o vídeo.
                    </video>
                ) : (
                    <img
                        className="hero-banner"
                        src={hero.image}
                        alt={`Hero Banner ${hero.title}`}
                    />
                )}
                {hero.overlayColor && (
                    <div className="overlay" style={{
                        backgroundColor: hero.overlayColor
                    }}></div>
                )}

                <div className="content">
                    <h1 style={{
                        color: hero.titleColor,
                        WebkitTextStroke: "1px #000",
                        textShadow: `0 0 5px ${hero.highlightColor}, 0 0 10px ${hero.highlightColor}, 0 0 20px ${hero.highlightColor}, 0 0 40px ${hero.highlightColor}, 0 0 80px ${hero.highlightColor}, 0 0 10px ${hero.highlightColor}`
                    }}>
                        {hero.title}
                    </h1>

                    <h2 style={{ color: hero.subTtitleColor }}>
                        {hero.subTtitle}
                    </h2>
                    <Button data={hero} />
                </div>
            </div>
        </div>
    );
}
