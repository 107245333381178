import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from '../Pages/Home';
import { Sites } from '../Pages/Sites';
import { Freelance } from '../Pages/Freelance';
import { ContactPage } from '../Pages/ContactPage';
import { AboutPage } from '../Pages/AboutPage';
import { Restyling } from '../Pages/Restyling';
import { LandingPages } from '../Pages/LandingPages';

export function RoutesT() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/criacao-sites" element={<Sites/>}/>
        <Route path="/reestilização" element={<Restyling/>}/>
        <Route path="/landing-pages" element={<LandingPages/>}/>
        <Route path="/freelance" element={<Freelance/>}/>
        <Route path="/contato" element={<ContactPage/>}/>
        <Route path="/sobre" element={<AboutPage/>}/>
      </Routes>
  );
}