import { Helmet } from 'react-helmet';
import { Cta } from "../Components/Cta";
import { Feature } from "../Components/Feature";
import { Hero } from "../Components/Hero";
import { NavBar } from "../Components/NavBar";
import { Portfolio } from "../Components/Portifolio";
import { SideBar } from "../Components/SideBar";
import { WorkFlow } from "../Components/WorkFlow";

import data from "../data.json";
import { AligneIcons } from '../Components/AligneIcons';
import { AligneText } from '../Components/AligneText';

export function Restyling() {
    const navData = data.NavBar;
    const sideData = data.SideBar;
    const heroData = data.Restyling.hero;
    const aligneData = data.Restyling.about;
    const featureData = data.Restyling.feature;
    const portfolioData = data.Restyling.portfolio;
    const aligneIconsData = data.Restyling.aligneIcons;
    const workFlowData = data.Restyling.workFlow;
    const ctaData = data.Restyling.cta;

    return (
        <div className="sites" style={{ backgroundColor: data.Restyling.backgroundColor }}>
            <Helmet>
                <title>{heroData.title} | Reestilização de Sites | Delleprane Studio</title>
                <meta name="description" content="Transforme seu site com a reestilização da Delleprane Studio. Oferecemos redesign moderno, melhorias em design e funcionalidade, e atualização de sites para refletir a identidade do seu negócio." />
                <meta name="keywords" content="reestilização de sites, redesign de sites, atualização de sites, melhoria de sites, modernização de sites, desenvolvimento de sites, criação de sites, portfólio, projetos criativos, Delleprane Studio, design moderno, sites responsivos, melhorias de SEO" />

                {/* Open Graph - SEO para redes sociais */}
                <meta property="og:title" content={`${heroData.title} | Reestilização de Sites | Delleprane Studio`} />
                <meta property="og:description" content="Atualize e modernize seu site com o serviço de reestilização da Delleprane Studio." />
                <meta property="og:image" content={heroData.image} />
                <meta property="og:url" content="https://www.delleprane.com.br/sites/reestilizacao" />
            </Helmet>


            <div className="restyling-container">
                <NavBar navData={navData} />
                <Hero hero={heroData} />
                <AligneText data={aligneData} />
                <Feature data={featureData} />
                <Portfolio data={portfolioData} />
                <AligneIcons data={aligneIconsData} />
                <WorkFlow data={workFlowData} />
                <Cta data={ctaData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    );
}
