import { useState } from "react";
import "./Feature.css"

interface FeatureData {
    icon: string;
    title: string;
    textColor: string;
    content: string
}

interface FeatureProps {
    data: FeatureData[]
}

export function Feature({ data }: FeatureProps) {
    const [openCardIndex, setOpenCardIndex] = useState<number | null>(null); // Controla o índice do card aberto

    const toggleCard = (index: number) => {
        if (openCardIndex === index) {
            setOpenCardIndex(null);
        } else {
            setOpenCardIndex(index);
        }
    };

    return (
        <section className="feature">
            <div className="feature-container">
                <div className="cards desktop">
                    {data.map((feature, index) => (
                        <div className="card" style={{ color: feature.textColor }} key={index} itemType="https://schema.org/Service">
                            <img loading="lazy" src={feature.icon} alt={`Ícone representando ${feature.title}: ${feature.content}`} />
                            <h3>
                                {feature.title}
                            </h3>
                            <p>{feature.content}</p>
                        </div>
                    ))}
                </div>

                <div className="cards mobile">
                    {data.map((card, index) => (
                        <div className="card" key={index} >
                            <button
                                aria-expanded={openCardIndex === index}
                                aria-controls={`feature-content-${index}`}
                                id={`feature-toggle-${index}`}
                                className="mobile-card"
                                onClick={() => toggleCard(index)}
                                style={{
                                    color: card.textColor,
                                }}
                            >
                                <img loading="lazy" src={card.icon} alt={`Ícone representando ${card.title}: ${card.content}`} className="icon" />
                                <h3>{card.title}</h3>
                                <span style={{ transform: openCardIndex === index ? "rotate(0deg)" : "rotate(90deg)" }}>
                                    <img loading="lazy" src="./images/icons/icon-arrow-white.webp" alt="Ícone de seta para expandir ou retrair o card" className="arrow" />
                                </span>
                            </button>
                            {openCardIndex === index && (
                                <div
                                    id={`feature-content-${index}`}
                                    role="region"
                                    aria-labelledby={`feature-toggle-${index}`}
                                    className="mobile-card-content"
                                    style={{ padding: "10px" }}
                                >
                                    <p style={{ color: card.textColor }}>
                                        {card.content}
                                    </p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}