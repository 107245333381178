import { AligneText } from "../Components/AligneText";
import { Carousel } from "../Components/Carousel";
import { Cta } from "../Components/Cta";
import { DropDownMenu } from "../Components/DropdownMenu/DropdownMenu";
import { Hero } from "../Components/Hero";
import { NavBar } from "../Components/NavBar";
import { SideBar } from "../Components/SideBar";
import { WorkFlow } from "../Components/WorkFlow";

import data from "../data.json";
import { Helmet } from 'react-helmet';


export function Home() {
    const navData = data.NavBar
    const heroData = data.Home.hero
    const sideData = data.SideBar
    const navigationData = data.Home.navigation
    const aligneTextData = data.Home.about
    const dropdownData = data.Home.dropdownMenu
    const workFlowData = data.Home.workFlow
    const ctaData = data.Home.cta

    return (
        <div className="home" style={{ backgroundColor: data.Home.backgroundColor }}>
            <Helmet>
                <title>Transforme sua Presença Online | Delleprane Studio</title>
                <meta name="description" content="No Delleprane Studio, criamos sites que combinam design moderno, funcionalidade e estratégia. Explore nossos serviços e descubra como transformar ideias em resultados online." />
                <meta name="keywords" content="desenvolvimento de sites, criação de sites, design de sites, sites responsivos, web design, desenvolvimento web, design personalizado, SEO, Delleprane Studio, soluções digitais, design moderno, sites profissionais, experiência do usuário, interface intuitiva, crescimento online, inovação digital" />

                {/* Open Graph - SEO para redes sociais */}
                <meta property="og:title" content="Transforme sua Presença Online | Delleprane Studio" />
                <meta property="og:description" content="Descubra como o Delleprane Studio pode levar seu negócio ao próximo nível com sites profissionais e personalizados. Design criativo." />
                <meta property="og:image" content={heroData.image} />
                <meta property="og:url" content="https://www.delleprane.com.br" />
            </Helmet>


            <div className="home-container">
                <NavBar navData={navData} />
                <Hero hero={heroData} />
                <Carousel data={navigationData} />
                <AligneText data={aligneTextData} />
                <DropDownMenu data={dropdownData} />
                <WorkFlow data={workFlowData} />
                <Cta data={ctaData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    )
}