import { EmailForm } from "../EmailForm";
import "./Contact.css";

interface Button {
    title: string;
    link: string;
    border:string;
    textColor: string;
}

interface ContactItems {
    icon: string;
    content: string;
    link: string;
    type: string;
}

interface ContactData {
    title: string;
    textColor: string;
    backgroundColor:string;
    templateId: string;
    border:string;
    backgroundImage:string;
    contactItens: ContactItems[];
    buttons: Button[];
}

interface ContactProps {
    data: ContactData;
}

export function Contact({ data }: ContactProps) {
    return (
        <section className="contact" aria-labelledby="contact-title" role="region">
            <div className="contact-container">
                <h2 id="contact-title" style={{ color: data.textColor,WebkitTextStroke: "1px #000" }}>
                    {data.title}
                </h2>
                <div className="contact-items">
                    {data.contactItens.map((contact, index) => {
                        let content;

                        if (contact.type ===  "telephone" || "instagram") {
                            content = (
                                <div className={`${contact.type} contact-item`}>
                                    <a
                                        key={index}
                                        className={`${contact.type}`}
                                        target="_blank"
                                        href={contact.link}
                                        rel="noopener noreferrer"
                                        aria-label={`Ligue para ${contact.content}`}
                                        style={{ color: data.textColor }}
                                    >
                                        <img
                                            loading="lazy"
                                            src={contact.icon}
                                            alt={`Ícone de ${contact.type}`}
                                        />
                                        {contact.content}
                                    </a>
                                </div>
                            );
                        } else if (contact.type === "email") {
                            content = (
                                <div className="email contact-item">
                                    <a
                                        key={index}
                                        href={`mailto:${contact.content}`}
                                        style={{ color: data.textColor }}
                                        aria-label={`Envie um e-mail para ${contact.content}`}
                                    >
                                        <img
                                            loading="lazy"
                                            src={contact.icon}
                                            alt={`Ícone de ${contact.type}`}
                                        />
                                        {contact.content}
                                    </a>
                                </div>
                            );
                        }

                        return content;
                    })}
                </div>
                <div className="background" style={{ backgroundImage: `url(${data.backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                <div className="email-form">
                    <EmailForm
                        templateID={data.templateId}
                        backgroundColor={data.backgroundColor}
                        border={data.border}
                    />
                </div>
                </div>
            </div>
        </section>
    );
}
