import { Helmet } from 'react-helmet';
import { Cta } from "../Components/Cta";
import { Feature } from "../Components/Feature";
import { Hero } from "../Components/Hero";
import { NavBar } from "../Components/NavBar";
import { Portfolio } from "../Components/Portifolio";
import { SideBar } from "../Components/SideBar";
import { WorkFlow } from "../Components/WorkFlow";

import data from "../data.json";
import { AligneIcons } from '../Components/AligneIcons';
import { About } from '../Components/About';
import { AligneText } from '../Components/AligneText';

export function Sites() {
    const navData = data.NavBar;
    const sideData = data.SideBar;
    const heroData = data.Sites.hero;
    const aligneData = data.Sites.about;
    const featureData = data.Sites.feature;
    const portfolioData = data.Sites.portfolio;
    const aligneIconsData = data.Sites.aligneIcons;
    const workFlowData = data.Sites.workFlow;
    const ctaData = data.Sites.cta;

    return (
        <div className="sites" style={{ backgroundColor: data.Sites.backgroundColor }}>
            <Helmet>
                <title>{heroData.title} | Criação de Sites Profissionais | Delleprane Studio</title>
                <meta name="description" content="Descubra como a Delleprane Studio transforma ideias em sites profissionais e personalizados. Criamos soluções digitais responsivas, focadas em design moderno e experiência do usuário." />
                <meta name="keywords" content="criação de sites, desenvolvimento de sites, design de sites, sites responsivos, web design, sites personalizados, experiência do usuário, Delleprane Studio, soluções digitais, SEO" />

                {/* Open Graph */}
                <meta property="og:title" content={`${heroData.title} | Criação de Sites | Delleprane Studio`} />
                <meta property="og:description" content="Transforme sua presença online com sites profissionais e criativos da Delleprane Studio. Oferecemos soluções em web design e SEO para impulsionar seu negócio." />
                <meta property="og:image" content={heroData.image} />
                <meta property="og:url" content="https://www.delleprane.com.br/sites" />
            </Helmet>


            <div className="sites-container">
                <NavBar navData={navData} />
                <Hero hero={heroData} />
                <AligneText data={aligneData} />
                <Feature data={featureData} />
                <Portfolio data={portfolioData} />
                <AligneIcons data={aligneIconsData} />
                <WorkFlow data={workFlowData} />
                <Cta data={ctaData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    );
}
